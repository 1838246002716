import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Field } from '../game/Field';
import { findLetterPosition, useGame } from '../game/useGame';
import { ampli } from '../lib/analytics';
import { createModal } from './Modal';
import { Box, Text } from './primitives';

const demoDict = ['pen', 'plan', 'panel'];

const Demo = () => {
  const { word, field, score, toggleCell, checkWord, reset } = useGame(
    0,
    demoDict,
  );
  const demoField = field.slice(0, 2);

  const prevScore = useRef(0);
  const [lastWordScore, setLastWordScore] = useState(0);

  useEffect(() => {
    setLastWordScore(Math.max(0, score - prevScore.current));
    prevScore.current = score;
  }, [score]);

  const [commandIndex, setCommandIndex] = useState(0);

  useEffect(() => {
    const selectLetter = (letter: string) => {
      const position = findLetterPosition(demoField, letter);

      if (position) {
        toggleCell(position);
      }
    };

    const commands = [
      () => selectLetter('p'),
      () => selectLetter('e'),
      () => selectLetter('n'),
      () => checkWord(),
      () => selectLetter('p'),
      () => selectLetter('l'),
      () => selectLetter('a'),
      () => selectLetter('n'),
      () => checkWord(),
      () => selectLetter('p'),
      () => selectLetter('a'),
      () => selectLetter('n'),
      () => selectLetter('e'),
      () => selectLetter('l'),
      () => checkWord(),
      () => reset(),
    ];

    const timeout = setTimeout(() => {
      commands[commandIndex]();

      setCommandIndex((currentIndex) =>
        currentIndex === commands.length - 1 ? 0 : currentIndex + 1,
      );
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [checkWord, commandIndex, demoField, reset, toggleCell]);

  return (
    <Box>
      <Text
        variant="l"
        marginBottom="s"
        textAlign="center"
        textTransform="uppercase"
      >
        {word || (lastWordScore ? `+${lastWordScore}` : ' ')}
      </Text>

      <Field field={demoField} />
    </Box>
  );
};

const RulesModalContent: FunctionComponent = () => {
  useEffect(() => {
    ampli.modalViewed({ modalName: 'rules' });
  }, []);

  return (
    <Box>
      <Box marginBottom="l">
        <Demo />
      </Box>

      <Text variant="body" marginBottom="m">
        wordtres is a word search game where you can use each letter on a field
        up to 3 times. Get more points by finding as many words as you can. Look
        for long words – they give more points.
      </Text>

      <Text variant="body" marginBottom="m">
        Select letters from the field to make a word, then click the "Check
        word" button. Cells will change color as you use them. The game ends
        when a red cell appears on the field.
      </Text>

      <Text variant="body">
        Each word can be used once and must be present in the game dictionary
        which contains only nouns in a singular form.
      </Text>
    </Box>
  );
};

export const RulesModal = createModal(RulesModalContent, 'How to play');
