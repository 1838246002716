type Field = { usageCount: number }[][];

type AchievementRule = {
  difficulty: number;
  emoji: string;
  description: string;
  checkFn: (field: Field, usedWords: string[]) => boolean;
};

export type Achievement = Pick<AchievementRule, 'emoji' | 'description'> & {
  id: string;
};

const MIN_CELL_USAGE_COUNT = 1;
const MAX_CELL_USAGE_COUNT = 3;

const MIN_WORD_LENGTH = 3;
const MAX_WORD_LENGTH = 10;

const DIFFICULTY_EASY = 1;
const DIFFICULTY_HARD = 2;

const countCells = (
  field: Field,
  cellConditionFn: (cell: { usageCount: number }) => boolean,
) => {
  let cellsCount = 0;

  field.forEach((row) => {
    row.forEach((cell) => {
      if (cellConditionFn(cell)) {
        cellsCount += 1;
      }
    });
  });

  return cellsCount;
};

// id -> rule
export const achievements: Record<string, AchievementRule> = {
  // Easy
  threeRed: {
    difficulty: DIFFICULTY_EASY,
    emoji: '🔥',
    description: '3+ red cells at the end',
    checkFn: (field) => {
      const redCells = countCells(
        field,
        (cell) => cell.usageCount === MAX_CELL_USAGE_COUNT,
      );

      return redCells >= 3;
    },
  },
  longWord: {
    difficulty: DIFFICULTY_EASY,
    emoji: '🐍',
    description: 'Used lo-o-ong word',
    checkFn: (field, usedWords) => {
      return usedWords.some((word) => word.length >= MAX_WORD_LENGTH);
    },
  },
  onlyShorts: {
    difficulty: DIFFICULTY_EASY,
    emoji: '🩳',
    description: 'Played only short words',
    checkFn: (field, usedWords) => {
      return usedWords.every((word) => word.length === MIN_WORD_LENGTH);
    },
  },
  redCenter: {
    difficulty: DIFFICULTY_EASY,
    emoji: '🎯',
    description: 'Central cell is red',
    checkFn: (field) => {
      return field[2][2].usageCount === MAX_CELL_USAGE_COUNT;
    },
  },
  allColors: {
    difficulty: DIFFICULTY_EASY,
    emoji: '🥗',
    description: 'All colors at the end',
    checkFn: (field) => {
      const hasCells = (count: number) => {
        return field.some((row) =>
          row.some(({ usageCount }) => {
            return usageCount === count;
          }),
        );
      };

      return hasCells(1) && hasCells(2) && hasCells(3);
    },
  },
  halfCellsUsed: {
    difficulty: DIFFICULTY_EASY,
    emoji: '⚖️',
    description: 'Used nearly half of cells',
    checkFn: (field) => {
      const usedCells = countCells(
        field,
        (cell) => cell.usageCount >= MIN_CELL_USAGE_COUNT,
      );

      return usedCells === 12 || usedCells === 13;
    },
  },

  // Hard
  allCellsUsed: {
    difficulty: DIFFICULTY_HARD,
    emoji: '💯',
    description: 'Used every cell',
    checkFn: (field) => {
      const usedCells = countCells(
        field,
        (cell) => cell.usageCount >= MIN_CELL_USAGE_COUNT,
      );

      return usedCells === 25;
    },
  },
  allRed: {
    difficulty: DIFFICULTY_HARD,
    emoji: '💥',
    description: 'All final cells are red',
    checkFn: (field) => {
      return field.every((row) =>
        row.every(({ usageCount }) => {
          const isUsed = usageCount >= MIN_CELL_USAGE_COUNT;
          return !isUsed || usageCount === MAX_CELL_USAGE_COUNT;
        }),
      );
    },
  },
};

export const getAchievements = (
  field: Field,
  usedWords: string[],
): Achievement[] => {
  return Object.entries(achievements)
    .filter(([, rule]) => {
      return rule.checkFn(field, usedWords);
    })
    .sort(([, ruleA], [, ruleB]) => {
      return ruleB.difficulty - ruleA.difficulty;
    })
    .slice(0, 3)
    .map(([id, rule]): Achievement => {
      return {
        id,
        emoji: rule.emoji,
        description: rule.description,
      };
    });
};
