import { FunctionComponent, useEffect, useState } from 'react';
import { achievements } from '../game/achievements';
import { getStatistics, Statistics } from '../game/statistics';
import { ampli } from '../lib/analytics';
import { AchievementBadge } from './AchievementBadge';
import { createModal } from './Modal';
import { Box, Text } from './primitives';

const StatisticsModalContent: FunctionComponent = () => {
  const [statistics, setStatistics] = useState<Statistics | null>(null);

  useEffect(() => {
    getStatistics().then(setStatistics);
  }, []);

  useEffect(() => {
    ampli.modalViewed({ modalName: 'statistics' });
  }, []);

  if (!statistics) {
    return null;
  }

  return (
    <Box>
      <Text variant="body" textAlign="center">
        Games played: {statistics.gamesPlayed}
      </Text>

      <Box flexDirection="row" justifyContent="center" flexWrap="wrap" gap="xl">
        {Object.entries(achievements).map(([id, achievement]) => {
          return (
            <AchievementBadge
              key={id}
              emoji={achievement.emoji}
              description={achievement.description}
              earnedTimes={statistics.earnedAchievements[id] ?? 0}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export const StatisticsModal = createModal(
  StatisticsModalContent,
  'Statistics',
);
