import { getAllItems, setItem } from '../lib/storage';
import { Achievement } from './achievements';

type Cell = {
  usageCount: number;
};

type GameResult = {
  id: number;
  words: string[];
  achievements: Achievement[];
  field: Cell[][];
  score: number;
};

type GameResultStored = {
  id: number;
  wordLengths: number[];
  cellStates: number[][];
  achievements: string[];
  score: number;
};

const STORAGE_KEY_PREFIX = 'en:result';

export const saveGameResults = async (result: GameResult) => {
  const data: GameResultStored = {
    id: result.id,
    wordLengths: result.words.map((word) => word.length),
    cellStates: result.field.map((row) => {
      return row.map((cell) => {
        return cell.usageCount;
      });
    }),
    achievements: result.achievements.map((achievement) => achievement.id),
    score: result.score,
  };

  return setItem(`${STORAGE_KEY_PREFIX}:${result.id}`, data);
};

export type Statistics = {
  gamesPlayed: number;
  earnedAchievements: Record<string, number>;
};

export const getStatistics = async (): Promise<Statistics> => {
  const gameResults: GameResultStored[] = await getAllItems(STORAGE_KEY_PREFIX);

  const earnedAchievements: Record<string, number> = {};
  gameResults.forEach((result) => {
    result.achievements.forEach((achievementId) => {
      const count = earnedAchievements[achievementId] ?? 0;
      earnedAchievements[achievementId] = count + 1;
    });
  });

  return {
    gamesPlayed: gameResults.length,
    earnedAchievements,
  };
};
