import {
  blackA,
  grass,
  grassDark,
  indigo,
  indigoDark,
  olive,
  oliveDark,
  tomato,
  tomatoDark,
  yellow,
  yellowDark,
} from '@radix-ui/colors';
import { createTheme, useTheme as useRestyleTheme } from '@shopify/restyle';

export { ThemeProvider } from '@shopify/restyle';

export const themeLight = createTheme({
  colors: {
    appBg: olive.olive1,

    modalBg: olive.olive1,
    modalShadow: blackA.blackA8,
    overlay: blackA.blackA8,

    toastBg: olive.olive12,
    toastText: olive.olive2,

    border: olive.olive6,

    text: olive.olive12,
    textError: tomato.tomato11,

    buttonText: grass.grass1,
    buttonIdleBg: grass.grass9,
    buttonHoveredBg: grass.grass10,
    buttonPressedBg: grass.grass11,

    buttonSecondaryText: grass.grass11,
    buttonSecondaryIdleBg: grass.grass3,
    buttonSecondaryHoveredBg: grass.grass4,
    buttonSecondaryPressedBg: grass.grass5,

    labelText: indigo.indigo1,
    labelBg: indigo.indigo9,
    labelShadow: indigo.indigo12,

    cell0Text: olive.olive11,
    cell0Bg: olive.olive4,
    cell0Border: olive.olive4,
    cell0HoveredBg: olive.olive5,
    cell0SelectedText: olive.olive12,
    cell0SelectedBg: olive.olive6,
    cell0SelectedBorder: olive.olive12,

    cell1Text: grass.grass11,
    cell1Bg: grass.grass4,
    cell1Border: grass.grass4,
    cell1HoveredBg: grass.grass5,
    cell1SelectedText: grass.grass12,
    cell1SelectedBg: grass.grass6,
    cell1SelectedBorder: grass.grass12,

    cell2Text: yellow.yellow11,
    cell2Bg: yellow.yellow4,
    cell2Border: yellow.yellow4,
    cell2HoveredBg: yellow.yellow5,
    cell2SelectedText: yellow.yellow12,
    cell2SelectedBg: yellow.yellow6,
    cell2SelectedBorder: yellow.yellow12,

    cell3Text: tomato.tomato11,
    cell3Bg: tomato.tomato4,
    cell3Border: tomato.tomato4,
    cell3HoveredBg: tomato.tomato5,
    cell3SelectedText: tomato.tomato12,
    cell3SelectedBg: tomato.tomato6,
    cell3SelectedBorder: tomato.tomato12,
  },
  spacing: {
    xs: 2,
    s: 4,
    m: 8,
    l: 16,
    xl: 24,
    xxl: 32,
    xxxl: 48,
  },
  borderRadii: {
    s: 4,
  },
  textVariants: {
    defaults: {
      color: 'text',
    },
    body: {
      fontSize: 16,
      lineHeight: 24,
    },
    l: {
      fontSize: 20,
      lineHeight: 28,
    },
    xl: {
      fontSize: 26,
      lineHeight: 32,
    },
    xxl: {
      fontSize: 32,
      lineHeight: 40,
    },
    xxxl: {
      fontSize: 40,
      lineHeight: 48,
    },
    xxxxl: {
      fontSize: 50,
      lineHeight: 60,
    },
  },
  breakpoints: {
    phone: 0,
    tablet: 768,
  },
});

export type Theme = typeof themeLight;

export const themeDark: Theme = {
  ...themeLight,
  colors: {
    appBg: oliveDark.olive2,

    modalBg: oliveDark.olive2,
    modalShadow: blackA.blackA12,
    overlay: blackA.blackA10,

    toastBg: oliveDark.olive12,
    toastText: oliveDark.olive2,

    border: oliveDark.olive6,

    text: oliveDark.olive12,
    textError: tomatoDark.tomato11,

    buttonText: grassDark.grass12,
    buttonIdleBg: grassDark.grass9,
    buttonHoveredBg: grassDark.grass10,
    buttonPressedBg: grassDark.grass11,

    buttonSecondaryText: grassDark.grass11,
    buttonSecondaryIdleBg: grassDark.grass3,
    buttonSecondaryHoveredBg: grassDark.grass4,
    buttonSecondaryPressedBg: grassDark.grass5,

    labelText: indigoDark.indigo1,
    labelBg: indigoDark.indigo9,
    labelShadow: indigoDark.indigo12,

    cell0Text: oliveDark.olive11,
    cell0Bg: oliveDark.olive4,
    cell0Border: oliveDark.olive4,
    cell0HoveredBg: oliveDark.olive5,
    cell0SelectedText: oliveDark.olive12,
    cell0SelectedBg: oliveDark.olive6,
    cell0SelectedBorder: oliveDark.olive12,

    cell1Text: grassDark.grass11,
    cell1Bg: grassDark.grass4,
    cell1Border: grassDark.grass4,
    cell1HoveredBg: grassDark.grass5,
    cell1SelectedText: grassDark.grass12,
    cell1SelectedBg: grassDark.grass6,
    cell1SelectedBorder: grassDark.grass12,

    cell2Text: yellowDark.yellow11,
    cell2Bg: yellowDark.yellow4,
    cell2Border: yellowDark.yellow4,
    cell2HoveredBg: yellowDark.yellow5,
    cell2SelectedText: yellowDark.yellow12,
    cell2SelectedBg: yellowDark.yellow6,
    cell2SelectedBorder: yellowDark.yellow12,

    cell3Text: tomatoDark.tomato11,
    cell3Bg: tomatoDark.tomato4,
    cell3Border: tomatoDark.tomato4,
    cell3HoveredBg: tomatoDark.tomato5,
    cell3SelectedText: tomatoDark.tomato12,
    cell3SelectedBg: tomatoDark.tomato6,
    cell3SelectedBorder: tomatoDark.tomato12,
  },
};

export const useTheme = () => useRestyleTheme<Theme>();
