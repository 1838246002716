import { createBox, createText } from '@shopify/restyle';
import { Animated } from 'react-native';
import { Theme } from './theme';

export const Box = createBox<Theme>();

export const Text = createText<Theme>();

export const AnimatedBox = Animated.createAnimatedComponent(Box);

export const AnimatedText = Animated.createAnimatedComponent(Text);
