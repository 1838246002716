import { useEffect } from 'react';
import { themeDark, themeLight } from './theme';

export const useStatusBarStylePWA = (colorScheme: 'dark' | 'light') => {
  useEffect(() => {
    const metaTheme = document?.querySelector('meta[name="theme-color"]');

    if (!metaTheme) {
      console.warn('No theme-color meta tag found');
      return;
    }

    // @ts-expect-error
    metaTheme.content =
      colorScheme === 'dark' ? themeDark.colors.appBg : themeLight.colors.appBg;
  }, [colorScheme]);
};
