import { useCallback, useEffect, useMemo, useState } from 'react';
import { getAchievements } from './achievements';
import { generateField } from './fieldGenerator';

type Cell = {
  letter: string;
  usageCount: number;
};

export type CellWithSelection = Cell & {
  selected: boolean;
};

export type Position = {
  rowIndex: number;
  colIndex: number;
};

export const findLetterPosition = (
  field: CellWithSelection[][],
  letter: string,
): Position | null => {
  let position: Position | null = null;
  let minUsageCount = 0;

  for (let rowIndex = 0; rowIndex < field.length; rowIndex++) {
    for (let colIndex = 0; colIndex < field[rowIndex].length; colIndex++) {
      const cell = field[rowIndex][colIndex];

      if (cell.letter !== letter || cell.selected) {
        continue;
      }

      if (position === null || cell.usageCount < minUsageCount) {
        minUsageCount = cell.usageCount;
        position = { rowIndex, colIndex };
      }

      if (position && minUsageCount === 0) {
        return position;
      }
    }
  }

  return position;
};

const isSamePosition = (a: Position, b: Position) => {
  return a.rowIndex === b.rowIndex && a.colIndex === b.colIndex;
};

const MAX_USAGE_COUNT = 3;

export const useGame = (fieldId: number, dictionary: string[]) => {
  const [score, setScore] = useState(0);
  const [cells, setCells] = useState<Cell[][]>([]);
  const [selectedCells, setSelectedCells] = useState<Position[]>([]);
  const [availableWords, setAvailableWords] = useState(new Set<string>());
  const [usedWords, setUsedWords] = useState(new Set<string>());

  useEffect(() => {
    setAvailableWords(new Set(dictionary));
  }, [dictionary]);

  const reset = useCallback(() => {
    const fieldSource = generateField(fieldId);

    const indexes = Array.from({ length: 5 }).map((_, i) => i);

    const newCells = indexes.map((i) => {
      return indexes.map((j) => {
        return {
          letter: fieldSource[i * 5 + j],
          usageCount: 0,
        };
      });
    });
    setCells(newCells);
    setSelectedCells([]);
    setUsedWords(new Set());
    setScore(0);
  }, [fieldId]);

  // Init
  useEffect(() => {
    reset();
  }, [reset]);

  const field = useMemo(() => {
    return cells?.map((row, rowIndex) => {
      return row.map((cell, colIndex) => {
        return {
          ...cell,
          selected: !!selectedCells.find((position) =>
            isSamePosition(position, { rowIndex, colIndex }),
          ),
        };
      });
    });
  }, [cells, selectedCells]);

  const word = useMemo(() => {
    return selectedCells
      .map((position) => cells?.[position.rowIndex][position.colIndex].letter)
      .join('');
  }, [cells, selectedCells]);

  const isFinished = field?.some((row) =>
    row.some((cell) => cell.usageCount === MAX_USAGE_COUNT),
  );

  const achievements = isFinished
    ? getAchievements(field, Array.from(usedWords))
    : [];

  const toggleCell = (selectPosition: Position) => {
    const isSelected = !!selectedCells.find((position) =>
      isSamePosition(position, selectPosition),
    );

    const newValue = isSelected
      ? selectedCells.filter(
          (position) => !isSamePosition(position, selectPosition),
        )
      : [...selectedCells, selectPosition];

    setSelectedCells(newValue);
  };

  const unselectLastCell = () => {
    if (selectedCells.length === 0) {
      return;
    }

    setSelectedCells(selectedCells.slice(0, selectedCells.length - 1));
  };

  const checkWord = (): boolean => {
    if (usedWords.has(word)) {
      return false;
    }

    if (!availableWords.has(word)) {
      return false;
    }

    const newCells = cells.map((row, rowIndex) => {
      return row.map((cell, colIndex) => {
        const wasUsed = selectedCells.find((position) =>
          isSamePosition(position, { rowIndex, colIndex }),
        );

        return {
          ...cell,
          usageCount: wasUsed ? cell.usageCount + 1 : cell.usageCount,
        };
      });
    });

    setScore((currentScore) => {
      const wordLength = word.length;

      return currentScore + wordLength * (50 + 5 * (wordLength - 3));
    });
    setCells(newCells);
    setSelectedCells([]);
    setUsedWords((currentUsedWords) => currentUsedWords.add(word));

    return true;
  };

  return {
    score,
    achievements,
    usedWords,
    word,
    field,
    isFinished,
    toggleCell,
    unselectLastCell,
    checkWord,
    reset,
  };
};
