// https://github.com/bryc/code/blob/master/jshash/PRNGs.md#mulberry32
const createRandomGenerator = (seed: number) => {
  return () => {
    seed |= 0;
    seed = (seed + 0x6d2b79f5) | 0;
    let t = Math.imul(seed ^ (seed >>> 15), 1 | seed);
    t = (t + Math.imul(t ^ (t >>> 7), 61 | t)) ^ t;
    return ((t ^ (t >>> 14)) >>> 0) / 4294967296;
  };
};

const shuffle = <T>(arr: T[], random: () => number) => {
  for (let i = 0; i < arr.length - 1; i++) {
    const j = Math.round(i + random() * (arr.length - i - 1));
    const tmp = arr[i];
    arr[i] = arr[j];
    arr[j] = tmp;
  }

  return arr;
};

const split = <T>(arr: T[], point: number) => {
  return [arr.slice(0, point), arr.slice(point, arr.length)];
};

export const generateField = (seed: number): string[] => {
  const random = createRandomGenerator(seed);

  const x3Set = 'eaio';
  const x2Set = 'rntslcmudp';
  const x1Set = 'hgbykfwvzxjq';

  const [x3, x3Rest] = split(shuffle(x3Set.split(''), random), 2);
  const [x2, x2Rest] = split(shuffle(x2Set.split(''), random), 2);
  const [x1] = split(shuffle(x1Set.split(''), random), 5);

  return shuffle(
    [...x3, ...x3, ...x3, ...x3Rest, ...x2, ...x2, ...x2Rest, ...x1],
    random,
  );
};
