import Icon from '@expo/vector-icons/Ionicons';
import { ComponentType, FunctionComponent, PropsWithChildren } from 'react';
import { Modal as RNModal, ScrollView } from 'react-native';
import { Box, Text } from './primitives';
import { useTheme } from './theme';

type ModalControlProps = {
  isOpen: boolean;
  onClose?: () => void;
};

type Props = ModalControlProps & {
  title: string;
};

const Modal: FunctionComponent<PropsWithChildren<Props>> = ({
  title,
  isOpen,
  children,
  onClose,
}) => {
  const theme = useTheme();

  return (
    <RNModal
      transparent
      animationType="fade"
      visible={isOpen}
      onRequestClose={onClose}
    >
      <Box height="100%" alignItems="center" backgroundColor="overlay">
        <Box
          flex={1}
          alignItems="center"
          justifyContent="center"
          maxWidth="100%"
          aspectRatio={0.6}
          padding="xl"
        >
          <Box
            position="relative"
            width="100%"
            maxHeight="95%"
            paddingVertical="xxl"
            backgroundColor="modalBg"
            borderRadius="s"
            shadowRadius={16}
            shadowColor="modalShadow"
          >
            {onClose ? (
              <Box
                position="absolute"
                top={theme.spacing.m}
                right={theme.spacing.m}
              >
                <Icon.Button
                  color={theme.colors.text}
                  iconStyle={{ margin: 0 }}
                  backgroundColor="transparent"
                  name="close"
                  onPress={onClose}
                />
              </Box>
            ) : null}

            <Text
              variant="xl"
              textAlign="center"
              marginBottom="l"
              marginHorizontal="xxl"
            >
              {title}
            </Text>

            <ScrollView style={{ paddingHorizontal: theme.spacing.xxl }}>
              {children}
            </ScrollView>
          </Box>
        </Box>
      </Box>
    </RNModal>
  );
};

export function createModal<Props extends object>(
  ModalContent: ComponentType<Props>,
  title: string,
) {
  const FullModal: FunctionComponent<Props & ModalControlProps> = ({
    isOpen,
    onClose,
    ...contentProps
  }) => {
    return (
      <Modal title={title} isOpen={isOpen} onClose={onClose}>
        <ModalContent {...(contentProps as Props)} />
      </Modal>
    );
  };

  FullModal.displayName = `createModal(${
    ModalContent.displayName ?? ModalContent.name
  })`;

  return FullModal;
}
