import { StatusBar } from 'expo-status-bar';
import { useColorScheme } from 'react-native';
import { Game } from './src/game/Game';
import { ampli } from './src/lib/analytics';
import { getVersion, useExposeVersionForPWA } from './src/lib/version';
import { ScreenWrapper } from './src/ui/ScreenWrapper';
import { themeDark, themeLight, ThemeProvider } from './src/ui/theme';
import { useStatusBarStylePWA } from './src/ui/useStatusBarStylePWA';

ampli.load({
  // disabled: __DEV__,
  client: {
    apiKey: '6b0df73aacbd6657c8096ccdbdc71eb1',
    configuration: {
      disableCookies: true,
      appVersion: getVersion(),
    },
  },
});

export default function App() {
  const colorScheme = useColorScheme();
  useStatusBarStylePWA(colorScheme ?? 'light');

  useExposeVersionForPWA();

  return (
    <ThemeProvider theme={colorScheme === 'dark' ? themeDark : themeLight}>
      <ScreenWrapper>
        <Game />
      </ScreenWrapper>

      <StatusBar style="auto" />
    </ThemeProvider>
  );
}
