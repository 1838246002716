import {
  FunctionComponent,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Animated, Easing } from 'react-native';
import enDictionary from '../../dictionaries/dict-en.json';
import { ampli } from '../lib/analytics';
import { AnimatedBox, Box } from '../ui/primitives';
import { GameFinishedScreen } from './GameFinishedScreen';
import { GameScreen } from './GameScreen';
import { saveGameResults } from './statistics';
import { useFieldId } from './useFieldId';
import { useGame } from './useGame';

const Transition: FunctionComponent<{
  isFinished: boolean;
  gameScreen: ReactNode;
  gameFinishedScreen: ReactNode;
}> = ({ isFinished, gameScreen, gameFinishedScreen }) => {
  const [state, setState] = useState<'game' | 'transition' | 'finished'>(
    isFinished ? 'finished' : 'game',
  );

  const gameFadeAnimation = useRef(new Animated.Value(1)).current;
  const gameFinishedFadeAnimation = useRef(new Animated.Value(0)).current;
  const gameFinishedOffsetAnimation = useRef(new Animated.Value(400)).current;

  useEffect(() => {
    setState('transition');

    const animationConfig = {
      delay: 50,
      duration: 300,
      easing: Easing.out(Easing.cubic),
      useNativeDriver: true,
    };

    Animated.parallel([
      Animated.timing(gameFadeAnimation, {
        ...animationConfig,
        toValue: isFinished ? 0 : 1,
      }),
      Animated.timing(gameFinishedFadeAnimation, {
        ...animationConfig,
        toValue: isFinished ? 1 : 0,
      }),
      Animated.timing(gameFinishedOffsetAnimation, {
        ...animationConfig,
        toValue: isFinished ? 0 : 400,
      }),
    ]).start(() => {
      setState(isFinished ? 'finished' : 'game');
    });
  }, [
    gameFadeAnimation,
    gameFinishedFadeAnimation,
    gameFinishedOffsetAnimation,
    isFinished,
  ]);

  return (
    <Box position="relative" flex={1} width="100%">
      {state !== 'finished' ? (
        <AnimatedBox
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          opacity={gameFadeAnimation}
        >
          {gameScreen}
        </AnimatedBox>
      ) : null}

      {state !== 'game' ? (
        <AnimatedBox
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          opacity={gameFinishedFadeAnimation}
          style={{
            transform: [
              { translateY: gameFinishedOffsetAnimation },
              { perspective: 1000 }, // without this line this Animation will not render on Android while working fine on iOS
            ],
          }}
        >
          {gameFinishedScreen}
        </AnimatedBox>
      ) : null}
    </Box>
  );
};

export const Game: FunctionComponent = () => {
  const { fieldId, recalculateFieldId } = useFieldId();

  const {
    score,
    achievements,
    usedWords,
    word,
    field,
    isFinished,
    toggleCell,
    unselectLastCell,
    checkWord,
    reset,
  } = useGame(fieldId, enDictionary);

  // Save game results on finish
  useEffect(() => {
    if (!isFinished) {
      return;
    }

    saveGameResults({
      id: fieldId,
      achievements,
      field,
      words: Array.from(usedWords.values()),
      score,
    });
  }, [achievements, field, fieldId, isFinished, score, usedWords]);

  // Analytics
  useEffect(() => {
    if (!isFinished) {
      ampli.gameStarted({ gameId: fieldId });
    }
  }, [fieldId, isFinished]);

  useEffect(() => {
    if (isFinished) {
      ampli.gameFinished({
        gameId: fieldId,
        gameScore: score,
        gameAchievements: achievements.map((achievement) => achievement.id),
        gameWordLengths: Array.from(usedWords.values()).map(
          (word) => word.length,
        ),
      });
    }
  }, [isFinished, fieldId, score, achievements, usedWords]);

  return (
    <Transition
      isFinished={isFinished}
      gameScreen={
        <GameScreen
          fieldId={fieldId}
          word={word}
          score={score}
          field={field}
          checkWord={checkWord}
          toggleCell={toggleCell}
          unselectLastCell={unselectLastCell}
        />
      }
      gameFinishedScreen={
        <GameFinishedScreen
          fieldId={fieldId}
          field={field}
          score={score}
          achievements={achievements}
          restartGame={reset}
          startNewGame={recalculateFieldId}
        />
      }
    />
  );
};
