import { FunctionComponent, PropsWithChildren, useEffect } from 'react';
import { Dimensions, SafeAreaView, StyleSheet } from 'react-native';
import { getItem, setItem } from '../lib/storage';
import { Header } from './Header';
import { Box } from './primitives';
import { RulesModal } from './RulesModal';
import { StatisticsModal } from './StatisticsModal';
import { useTheme } from './theme';
import { useModalState } from './useModalState';

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center',
    maxHeight: Dimensions.get('window').height,
  },
});

export const ScreenWrapper: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const theme = useTheme();

  const rulesModal = useModalState();

  useEffect(() => {
    getItem('isIntroShown').then((isIntroShown: boolean) => {
      if (!isIntroShown) {
        rulesModal.open();
      }

      setItem('isIntroShown', true);
    });

    // Should run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const statisticsModal = useModalState();

  return (
    <SafeAreaView
      style={[styles.container, { backgroundColor: theme.colors.appBg }]}
    >
      <Box
        flex={1}
        aspectRatio={0.6}
        maxWidth="100%"
        paddingHorizontal="xxl"
        paddingVertical="xl"
      >
        <RulesModal isOpen={rulesModal.isOpen} onClose={rulesModal.close} />

        <StatisticsModal
          isOpen={statisticsModal.isOpen}
          onClose={statisticsModal.close}
        />

        <Box flexShrink={0} flexGrow={0}>
          <Header
            onOpenHelp={rulesModal.open}
            onOpenStatistics={statisticsModal.open}
          />
        </Box>

        {children}
      </Box>
    </SafeAreaView>
  );
};
