import AsyncStorage from '@react-native-async-storage/async-storage';

const STORAGE_KEY_PREFIX = 'wt';

const getKey = (key: string) => `${STORAGE_KEY_PREFIX}:${key}`;

export const getItem = async (key: string) => {
  const value = await AsyncStorage.getItem(getKey(key));

  return value ? JSON.parse(value) : value;
};

export const getAllItems = async (keyPrefix: string) => {
  const fullPrefix = getKey(keyPrefix);

  const keys = await AsyncStorage.getAllKeys();
  const itemKeys = keys.filter((key) => key.startsWith(fullPrefix));

  const items = await AsyncStorage.multiGet(itemKeys);

  return items.map(([key, value]) => {
    return value ? JSON.parse(value) : value;
  });
};

export const setItem = (key: string, value: unknown) => {
  return AsyncStorage.setItem(getKey(key), JSON.stringify(value));
};
