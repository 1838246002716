import { differenceInDays } from 'date-fns';
import { useState } from 'react';

const getDailyId = () => {
  return 1 + differenceInDays(new Date(), new Date(2023, 2, 27, 0));
};

export const useFieldId = () => {
  const [fieldId, setFieldId] = useState(getDailyId());

  const recalculateFieldId = () => {
    setFieldId(getDailyId());
  };

  return { fieldId, recalculateFieldId };
};
