/* tslint:disable */
/* eslint-disable eslint-comments/no-unlimited-disable */
/* eslint-disable */
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull expo'
 *
 * Required dependencies: @amplitude/analytics-react-native@^0.4.0, @react-native-async-storage/async-storage@^1.17.9
 * Tracking Plan Version: 0
 * Build: 1.0.0
 * Runtime: react-native:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/wordtres/wordtres/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/wordtres/wordtres/implementation/expo)
 */

import * as amplitude from '@amplitude/analytics-react-native';

export type ReactNativeClient = amplitude.Types.ReactNativeClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
export type ReactNativeOptions = amplitude.Types.ReactNativeOptions;

export type Environment = 'default';

export const ApiKey: Record<Environment, string> = {
  default: '6b0df73aacbd6657c8096ccdbdc71eb1'
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: ReactNativeOptions = {
  plan: {
    version: '0',
    branch: 'main',
    source: 'expo',
    versionId: 'eab64573-b655-40f1-a5e6-1f407cdd19b4'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'react-native-typescript-ampli',
      sourceVersion: '2.0.0'
    }
  }
};

export interface LoadOptionsBase { disabled?: boolean }

export type LoadOptionsWithEnvironment = LoadOptionsBase & { environment: Environment; client?: { configuration?: ReactNativeOptions; }; };
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: ReactNativeOptions; } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: ReactNativeClient; } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface GameFinishedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  gameAchievements: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  gameId: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  gameScore: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | number |
   */
  gameWordLengths: number[];
}

export interface GameResultsSharedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  gameAchievements: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  gameId: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  gameScore: number;
}

export interface GameStartedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  gameId: number;
}

export interface GameWordPlayedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  gameId: number;
  gameWord: string;
  gameWordAccepted: boolean;
}

export interface ModalViewedProperties {
  modalName: string;
}

export interface GamePropsProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  gameId: number;
}

export class GameFinished implements BaseEvent {
  event_type = 'game.finished';

  constructor(
    public event_properties: GameFinishedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class GameResultsShared implements BaseEvent {
  event_type = 'game.resultsShared';

  constructor(
    public event_properties: GameResultsSharedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class GameStarted implements BaseEvent {
  event_type = 'game.started';

  constructor(
    public event_properties: GameStartedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class GameWordPlayed implements BaseEvent {
  event_type = 'game.wordPlayed';

  constructor(
    public event_properties: GameWordPlayedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ModalViewed implements BaseEvent {
  event_type = 'modal.viewed';

  constructor(
    public event_properties: ModalViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: ReactNativeClient;

  get client(): ReactNativeClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already initialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...options.client?.configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();

    return this.amplitude!.identify(amplitudeIdentify, options);
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * Flush pending events.
   */
  flush(): PromiseResult<void> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * game.finished
   *
   * [View in Tracking Plan](https://data.amplitude.com/wordtres/wordtres/events/main/latest/game.finished)
   *
   * Owner: Aleksandr Shepelin
   *
   * @param properties The event's properties (e.g. gameAchievements)
   * @param options Amplitude event options.
   */
  gameFinished(
    properties: GameFinishedProperties,
    options?: EventOptions,
  ) {
    return this.track(new GameFinished(properties), options);
  }

  /**
   * game.resultsShared
   *
   * [View in Tracking Plan](https://data.amplitude.com/wordtres/wordtres/events/main/latest/game.resultsShared)
   *
   * Owner: Aleksandr Shepelin
   *
   * @param properties The event's properties (e.g. gameAchievements)
   * @param options Amplitude event options.
   */
  gameResultsShared(
    properties: GameResultsSharedProperties,
    options?: EventOptions,
  ) {
    return this.track(new GameResultsShared(properties), options);
  }

  /**
   * game.started
   *
   * [View in Tracking Plan](https://data.amplitude.com/wordtres/wordtres/events/main/latest/game.started)
   *
   * Owner: Aleksandr Shepelin
   *
   * @param properties The event's properties (e.g. gameId)
   * @param options Amplitude event options.
   */
  gameStarted(
    properties: GameStartedProperties,
    options?: EventOptions,
  ) {
    return this.track(new GameStarted(properties), options);
  }

  /**
   * game.wordPlayed
   *
   * [View in Tracking Plan](https://data.amplitude.com/wordtres/wordtres/events/main/latest/game.wordPlayed)
   *
   * Owner: Aleksandr Shepelin
   *
   * @param properties The event's properties (e.g. gameId)
   * @param options Amplitude event options.
   */
  gameWordPlayed(
    properties: GameWordPlayedProperties,
    options?: EventOptions,
  ) {
    return this.track(new GameWordPlayed(properties), options);
  }

  /**
   * modal.viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/wordtres/wordtres/events/main/latest/modal.viewed)
   *
   * Owner: Aleksandr Shepelin
   *
   * @param properties The event's properties (e.g. modalName)
   * @param options Amplitude event options.
   */
  modalViewed(
    properties: ModalViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ModalViewed(properties), options);
  }
}

export const ampli = new Ampli();
