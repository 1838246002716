import { differenceInSeconds, startOfTomorrow } from 'date-fns';
import * as Clipboard from 'expo-clipboard';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Animated, Platform, Share } from 'react-native';
import { ampli } from '../lib/analytics';
import { AchievementBadge } from '../ui/AchievementBadge';
import { Button } from '../ui/Button';
import { AnimatedBox, Box, Text } from '../ui/primitives';
import { Achievement } from './achievements';

type Cell = {
  usageCount: number;
};

const getEmojiMap = (field: Cell[][]) => {
  return field
    .map((row) => {
      return row
        .map((cell) => {
          switch (cell.usageCount) {
            case 3:
              return '🟥';
            case 2:
              return '🟨';
            case 1:
              return '🟩';
            default:
              return '⬜';
          }
        })
        .join('');
    })
    .join('\n');
};

const HOUR_MS = 3600;
const MINUTE_MS = 60;

const pad = (value: number) => (value < 10 ? `0${value}` : `${value}`);

const formatDuration = (durationSec: number) => {
  const hours = Math.floor(durationSec / HOUR_MS);
  const restTime = durationSec % HOUR_MS;
  const minutes = Math.floor(restTime / MINUTE_MS);
  const seconds = restTime % MINUTE_MS;

  return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
};

type Props = {
  fieldId: number;
  score: number;
  achievements: Achievement[];
  field: Cell[][];
  restartGame: () => void;
  startNewGame: () => void;
};

export const GameFinishedScreen: FunctionComponent<Props> = ({
  fieldId,
  score,
  achievements,
  field,
  restartGame,
  startNewGame,
}) => {
  const emojiMap = getEmojiMap(field);

  const messageFadeAnimation = useRef(new Animated.Value(0)).current;
  const copyToClipboard = async () => {
    const achievementsEmoji = achievements
      .map((achievement) => achievement.emoji)
      .join('');
    const message = `wordtres #${fieldId}: ${score} ${achievementsEmoji}\n\n${emojiMap}`;

    if (Platform.OS === 'web') {
      if (globalThis.navigator?.share) {
        await globalThis.navigator.share({ text: message });
      } else {
        await Clipboard.setStringAsync(message);
        messageFadeAnimation.setValue(1);
        Animated.timing(messageFadeAnimation, {
          toValue: 0,
          delay: 500,
          duration: 500,
          useNativeDriver: true,
        }).start();
      }
    } else {
      await Share.share({ message });
    }

    ampli.gameResultsShared({
      gameId: fieldId,
      gameScore: score,
      gameAchievements: achievements.map((achievement) => achievement.id),
    });
  };

  const [timeToNextChallenge, setTimeToNextChallenge] = useState(0);

  useEffect(() => {
    const nextChallengeDate = startOfTomorrow();

    let timer: ReturnType<typeof setTimeout>;
    const updateTimeToNextChallenge = () => {
      const diff = Math.max(
        differenceInSeconds(nextChallengeDate, new Date()),
        0,
      );
      setTimeToNextChallenge(diff);

      if (diff > 0) {
        timer = setTimeout(updateTimeToNextChallenge, 500);
      }
    };

    updateTimeToNextChallenge();

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <Box flex={1} alignItems="center" justifyContent="flex-end">
      <Text
        variant="xxxl"
        fontWeight="bold"
        letterSpacing={0.25}
        marginTop="xl"
      >
        {score}
      </Text>

      {achievements.length ? (
        <Box marginTop="l" flexDirection="row" justifyContent="center" gap="l">
          {achievements.map((achievement) => (
            <AchievementBadge
              key={achievement.id}
              emoji={achievement.emoji}
              description={achievement.description}
            />
          ))}
        </Box>
      ) : null}

      <Text fontSize={32} lineHeight={34} letterSpacing={2} marginTop="l">
        {emojiMap}
      </Text>

      <Box position="relative" marginTop="l">
        <AnimatedBox
          position="absolute"
          top={-40}
          left={0}
          right={0}
          flexDirection="row"
          justifyContent="center"
          zIndex={1}
          style={{ opacity: messageFadeAnimation }}
        >
          <Box
            paddingVertical="s"
            paddingHorizontal="m"
            backgroundColor="toastBg"
            borderRadius="s"
          >
            <Text variant="body" color="toastText">
              Copied to clipboard
            </Text>
          </Box>
        </AnimatedBox>

        <Button
          variant="primary"
          title="Share results"
          onPress={copyToClipboard}
        />
      </Box>

      <Box marginTop="xxxl" justifyContent="space-between" gap="l">
        <Box alignItems="center">
          <Text variant="body">Next challenge in</Text>
          <Text variant="l">{formatDuration(timeToNextChallenge)}</Text>
        </Box>

        {timeToNextChallenge > 0 ? (
          <Button
            variant="secondary"
            title="Replay game"
            onPress={restartGame}
          />
        ) : (
          <Button
            variant="secondary"
            title="Play new game"
            onPress={startNewGame}
          />
        )}
      </Box>
    </Box>
  );
};
