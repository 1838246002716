import Icon from '@expo/vector-icons/Ionicons';
import { FunctionComponent } from 'react';
import { Box, Text } from './primitives';
import { useTheme } from './theme';

type Props = {
  onOpenHelp: () => void;
  onOpenStatistics: () => void;
};

export const Header: FunctionComponent<Props> = ({
  onOpenHelp,
  onOpenStatistics,
}) => {
  const theme = useTheme();

  return (
    <Box flexDirection="row" justifyContent="space-between">
      <Text variant="xl" textTransform="uppercase">
        wordtres
      </Text>

      <Box flexDirection="row" gap="m">
        <Icon.Button
          color={theme.colors.text}
          backgroundColor="transparent"
          iconStyle={{ marginHorizontal: 0 }}
          name="help-circle-outline"
          onPress={onOpenHelp}
        />

        <Icon.Button
          color={theme.colors.text}
          backgroundColor="transparent"
          iconStyle={{ marginHorizontal: 0 }}
          name="bar-chart-outline"
          onPress={onOpenStatistics}
        />
      </Box>
    </Box>
  );
};
