import { FunctionComponent } from 'react';
import { Box, Text } from './primitives';

type Props = {
  emoji: string;
  description: string;
  earnedTimes?: number;
};

export const AchievementBadge: FunctionComponent<Props> = ({
  emoji,
  description,
  earnedTimes,
}) => {
  const hasCounter = earnedTimes !== undefined;

  return (
    <Box
      alignItems="center"
      maxWidth={80}
      opacity={!hasCounter || earnedTimes ? 1 : 0.5}
    >
      <Box marginBottom="s">
        <Text variant="xxxl">{emoji}</Text>

        {hasCounter && earnedTimes ? (
          <Box
            position="absolute"
            bottom={-2}
            right={-12}
            padding="xs"
            width={28}
            alignItems="center"
            backgroundColor="labelBg"
            borderRadius="s"
          >
            <Text fontSize={12} color="labelText">
              {earnedTimes}
            </Text>
          </Box>
        ) : null}
      </Box>

      <Text fontSize={14} lineHeight={16} textAlign="center">
        {description}
      </Text>
    </Box>
  );
};
