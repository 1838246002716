import { FunctionComponent } from 'react';
import { Pressable } from 'react-native';
import { Box, Text } from '../ui/primitives';
import { Position } from './useGame';

type UsageCount = 0 | 1 | 2 | 3;

const Cell: FunctionComponent<{
  letter: string;
  usageCount: number;
  selected: boolean;
  onToggle?: () => void;
}> = ({ letter, usageCount, selected, onToggle }) => {
  const count = Math.min(usageCount, 3) as UsageCount;
  const selectedState = selected ? 'Selected' : '';

  return (
    <Pressable
      onPress={onToggle}
      disabled={!onToggle}
      // @ts-expect-error needed for web
      style={{ outline: 'none' }}
    >
      {(state) => {
        // @ts-expect-error Incomplete type in RNW
        const hoveredState = state.hovered ? 'Hovered' : selectedState;

        return (
          <Box
            width="100%"
            aspectRatio={1}
            alignItems="center"
            justifyContent="center"
            backgroundColor={`cell${count}${hoveredState}Bg`}
            borderColor={`cell${count}${selectedState}Border`}
            borderWidth={2}
            borderStyle="solid"
            borderRadius="s"
            // @ts-expect-error Keep for web
            style={{ userSelect: 'none' }}
          >
            <Text
              variant={{ phone: 'xxl', tablet: 'xxxl' }}
              color={`cell${count}${selectedState}Text`}
              textTransform="uppercase"
            >
              {letter}
            </Text>
          </Box>
        );
      }}
    </Pressable>
  );
};

type CellWithSelection = {
  letter: string;
  usageCount: number;
  selected: boolean;
};

export const Field: FunctionComponent<{
  field: CellWithSelection[][];
  onToggleCell?: (position: Position) => void;
}> = ({ field, onToggleCell }) => {
  return (
    <Box>
      {field.map((row, rowIndex) => (
        <Box
          key={rowIndex}
          width="100%"
          flexDirection="row"
          justifyContent="space-between"
          style={{ marginTop: rowIndex > 0 ? '2.5%' : 0 }}
        >
          {row.map((cell, cellIndex) => (
            <Box key={cellIndex} width="18%">
              <Cell
                letter={cell.letter}
                usageCount={cell.usageCount}
                selected={cell.selected}
                onToggle={
                  onToggleCell
                    ? () => onToggleCell({ rowIndex, colIndex: cellIndex })
                    : undefined
                }
              />
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  );
};
