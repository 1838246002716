import { useEffect } from 'react';
import { Platform } from 'react-native';
import { CellWithSelection, findLetterPosition, Position } from './useGame';

type Props = {
  field: CellWithSelection[][];
  onToggleCell: (position: Position) => void;
  onUnselectLastCell: () => void;
  onCheckWord: () => void;
};

export const useKeyboardControl = ({
  field,
  onToggleCell,
  onUnselectLastCell,
  onCheckWord,
}: Props) => {
  useEffect(() => {
    if (Platform.OS !== 'web' || !window.addEventListener) {
      return;
    }

    const handleKey = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        onCheckWord();
        return;
      }

      if (e.key === 'Backspace') {
        onUnselectLastCell();
        return;
      }

      if (e.key.length !== 1) {
        // probably not a letter key, skip
        return;
      }

      const position = findLetterPosition(field, e.key.toLowerCase());
      if (position) {
        onToggleCell(position);
      }
    };

    window.addEventListener('keyup', handleKey);

    return () => {
      window.removeEventListener('keyup', handleKey);
    };
  }, [field, onCheckWord, onToggleCell, onUnselectLastCell]);
};
