import * as Constants from 'expo-constants';
import { useEffect } from 'react';
import { Platform } from 'react-native';

export const getVersion = () => {
  return `${Constants.default.manifest?.version}${__DEV__ ? '-dev' : ''}`;
};

export const useExposeVersionForPWA = () => {
  useEffect(() => {
    if (Platform.OS !== 'web' || !window) {
      return;
    }

    // @ts-expect-error
    window.APP_VERSION = getVersion();
  }, []);
};
