import { FunctionComponent } from 'react';
import { Pressable } from 'react-native';
import { Box, Text } from './primitives';

type Props = {
  variant: 'primary' | 'secondary';
  title: string;
  onPress: () => void;
};

export const Button: FunctionComponent<Props> = ({
  variant,
  title,
  onPress,
}) => {
  const v = function <A, B>(options: [A, B]) {
    return variant === 'primary' ? options[0] : options[1];
  };

  return (
    <Pressable onPress={onPress}>
      {(state) => (
        <Box
          paddingVertical={v(['l', 'm'])}
          paddingHorizontal={v(['xl', 'l'])}
          alignItems="center"
          justifyContent="center"
          backgroundColor={
            state.pressed
              ? v(['buttonPressedBg', 'buttonSecondaryPressedBg'])
              : // @ts-expect-error Incomplete type in RNW
              state.hovered
              ? v(['buttonHoveredBg', 'buttonSecondaryHoveredBg'])
              : v(['buttonIdleBg', 'buttonSecondaryIdleBg'])
          }
          borderRadius="s"
        >
          <Text
            variant={v(['l', 'body'])}
            fontWeight="bold"
            letterSpacing={0.25}
            textTransform="uppercase"
            color={v(['buttonText', 'buttonSecondaryText'])}
            // @ts-expect-error Keep for web
            style={{ userSelect: 'none' }}
          >
            {title}
          </Text>
        </Box>
      )}
    </Pressable>
  );
};
