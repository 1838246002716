import Icon from '@expo/vector-icons/Ionicons';
import { FunctionComponent, useRef, useState } from 'react';
import { Animated, Easing } from 'react-native';
import { ampli } from '../lib/analytics';
import { Button } from '../ui/Button';
import { AnimatedText, Box } from '../ui/primitives';
import { useTheme } from '../ui/theme';
import { Field } from './Field';
import { Score } from './Score';
import { CellWithSelection, Position } from './useGame';
import { useKeyboardControl } from './useKeyboardControl';

type Props = {
  fieldId: number;
  score: number;
  word: string;
  field: CellWithSelection[][];
  toggleCell: (position: Position) => void;
  unselectLastCell: () => void;
  checkWord: () => boolean;
};

export const GameScreen: FunctionComponent<Props> = ({
  fieldId,
  score,
  word,
  field,
  toggleCell,
  unselectLastCell,
  checkWord,
}) => {
  const theme = useTheme();

  const [hasWordError, setHasWordError] = useState(false);
  const wrongWordAnimation = useRef(new Animated.Value(0)).current;

  const handleCheckWord = () => {
    const isAccepted = checkWord();

    if (!isAccepted) {
      setHasWordError(true);
      wrongWordAnimation.setValue(16);

      Animated.timing(wrongWordAnimation, {
        toValue: 0,
        duration: 500,
        easing: Easing.elastic(4),
        useNativeDriver: true,
      }).start((result) => {
        setHasWordError(!result.finished);
      });
    }

    ampli.gameWordPlayed({
      gameId: fieldId,
      gameWord: word,
      gameWordAccepted: isAccepted,
    });
  };

  useKeyboardControl({
    field,
    onToggleCell: toggleCell,
    onUnselectLastCell: unselectLastCell,
    onCheckWord: handleCheckWord,
  });

  return (
    <Box flex={1}>
      <Box
        flexShrink={0}
        flexGrow={1}
        alignItems="center"
        justifyContent="center"
        marginTop="xl"
      >
        <Score value={score} />
      </Box>

      <Box
        flexDirection="row"
        gap="m"
        flexShrink={0}
        flexGrow={0}
        marginTop="xl"
        alignItems="center"
        justifyContent="center"
      >
        <Box flex={1} alignItems="center">
          <AnimatedText
            variant="xxl"
            textTransform="uppercase"
            letterSpacing={theme.spacing.s}
            numberOfLines={1}
            // Keep same height even text is empty
            opacity={word.length > 0 ? 1 : 0}
            color={hasWordError ? 'textError' : 'text'}
            style={{
              marginLeft: 36,
              transform: [
                { translateX: wrongWordAnimation },
                { perspective: 1000 }, // without this line this Animation will not render on Android while working fine on iOS
              ],
            }}
          >
            {word || '.'}
          </AnimatedText>
        </Box>

        {word.length ? (
          <Icon.Button
            color={theme.colors.text}
            backgroundColor="transparent"
            iconStyle={{ marginHorizontal: 0 }}
            name="backspace-outline"
            onPress={unselectLastCell}
          />
        ) : null}
      </Box>

      <Box flexShrink={0} flexGrow={0} marginTop="xl">
        <Field field={field} onToggleCell={toggleCell} />
      </Box>

      <Box flexShrink={0} flexGrow={0} marginTop="xl">
        <Button
          variant="primary"
          title="Check word"
          onPress={handleCheckWord}
        />
      </Box>
    </Box>
  );
};
