import { FunctionComponent, useEffect, useState } from 'react';
import { Text } from '../ui/primitives';

export const Score: FunctionComponent<{ value: number }> = ({ value }) => {
  const [displayValue, setDisplayValue] = useState(value);

  useEffect(() => {
    if (value === 0) {
      return;
    }

    let timer: ReturnType<typeof setTimeout>;
    const updateDisplayValue = (diff: number) => {
      setDisplayValue(value - diff);

      if (diff > 0) {
        timer = setTimeout(() => {
          updateDisplayValue(diff - 1);
        }, 25 * (diff + 2));
      }
    };

    updateDisplayValue(3);

    return () => {
      clearTimeout(timer);
    };
  }, [value]);

  return (
    <Text
      variant={{ phone: 'xxxl', tablet: 'xxxxl' }}
      fontWeight="bold"
      letterSpacing={0.25}
    >
      {displayValue ? displayValue : ''}
    </Text>
  );
};
